
import './App.css';
import { Container } from './container/Container';
import { GlobalStyle } from './GlobalStyle';

function App() {
  return (
    <>
    <GlobalStyle />
   <Container />
  </>
  )
}

export default App;
